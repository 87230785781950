export const formatNumber = (inputValue: string) => {
  const splittedValue = inputValue.split('.');

  const [integerPart, decimalPart] = splittedValue;

  let formattedIntegerPart = integerPart.replace(/\D/g, '');

  formattedIntegerPart = formattedIntegerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ' '
  );

  const formattedDecimalPart =
    decimalPart || splittedValue.length > 1
      ? decimalPart.replace(/\D/g, '')
      : '';

  return formattedDecimalPart || splittedValue.length > 1
    ? `${formattedIntegerPart}.${formattedDecimalPart}`
    : formattedIntegerPart;
};
