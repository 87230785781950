import { ApiPath } from '../common/enums/http/api-path.enum';
import { ApiTag } from '../common/enums/http/api-tag.enum';
import { HttpMethod } from '../common/enums/http/http-method.enum';
import type { OptionItem } from '../common/types/option-item';
import type { ReponseWithPagination } from '../common/types/response-with-pagination';
import type { Project } from '../models/project';
import { commonApi } from './common-api';

export const projectsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<
      ReponseWithPagination<Project>,
      { params: string }
    >({
      query: ({ params }) => `${ApiPath.PROJECTS}${ApiPath.ROOT}${params}`,
      providesTags: [ApiTag.PROJECTS]
    }),
    getCurrencies: builder.query<
      ReponseWithPagination<{ id: number; title: string; code: string }>,
      { params: string }
    >({
      query: ({ params }) => `${ApiPath.CURRENCIES}${params}`
    }),
    getProjectsSteps: builder.query<
      ReponseWithPagination<OptionItem>,
      { params: string }
    >({
      query: ({ params }) => `${ApiPath.PROJECTS_STEPS}${params}`
    }),
    getArchivedProjects: builder.query<
      ReponseWithPagination<Project>,
      { params: string }
    >({
      query: ({ params }) =>
        `${ApiPath.PROJECTS_ARCHIVED}${ApiPath.ROOT}${params}`,
      providesTags: [ApiTag.PROJECTS]
    }),
    getCurrentProject: builder.query<
      Project,
      { id: string | number | undefined | null }
    >({
      query: ({ id }) =>
        `${ApiPath.PROJECTS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
      providesTags: [ApiTag.PROJECT]
    }),
    getCurrentArchProject: builder.query<Project, { id: string | undefined }>({
      query: ({ id }) =>
        `${ApiPath.PROJECTS_ARCHIVED}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
      providesTags: [ApiTag.PROJECT]
    }),
    createProject: builder.mutation<Project, Record<string, unknown>>({
      query: (data) => ({
        url: `${ApiPath.PROJECTS}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.PROJECTS]
    }),
    editProject: builder.mutation<
      Project,
      { id: string | number; body: Record<string, unknown> | FormData }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.PROJECTS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.PROJECTS, ApiTag.PROJECT, ApiTag.EVENTS]
    }),
    editArchProject: builder.mutation<
      Project,
      { id: string | number; body: Record<string, unknown> }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.PROJECTS_ARCHIVED}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.PROJECTS, ApiTag.PROJECT, ApiTag.EVENTS]
    }),
    delegateProject: builder.mutation<Project, Record<string, unknown>>({
      query: (data) => ({
        url: `${ApiPath.PROJECTS_DELEGATE}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.PROJECTS]
    }),
    deleteProject: builder.mutation<Project, { ids: (string | number)[] }>({
      query: ({ ids }) => ({
        url: `${ApiPath.PROJECTS}${ApiPath.ROOT}?ids=${ids.join(',')}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.PROJECTS]
    }),
    changeStep: builder.mutation({
      query: ({
        projectId,
        step
      }: {
        projectId: number | string;
        step: OptionItem;
        params: string;
        prevParams: string;
        project: Project;
      }) => ({
        url: `${ApiPath.PROJECTS}/${projectId}/`,
        method: HttpMethod.PATCH,
        body: {
          current_step: step.id
        }
      }),
      async onQueryStarted(
        { step, params, prevParams, project },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          projectsApi.util.updateQueryData(
            'getProjects',
            { params },
            (draft) => ({
              ...draft,
              count: draft.count + 1,
              results: [
                {
                  ...project,
                  current_step: step,
                  current_deadline:
                    project.step_deadlines.find(
                      (item) => item.step.id === step.id
                    )?.date || null,
                  current_resp:
                    project.step_deadlines.find(
                      (item) => item.step.id === step.id
                    )?.responsible || null
                },
                ...draft.results
              ]
            })
          )
        );
        const patchPrevResult = dispatch(
          projectsApi.util.updateQueryData(
            'getProjects',
            { params: prevParams },
            (draft) => ({
              ...draft,
              count: draft.count - 1,
              results: draft.results.filter(
                (item) => `${item.id}` !== `${project.id}`
              )
            })
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          patchPrevResult.undo();
        }
      }
    })
  }),
  overrideExisting: false
});

export const {
  useGetProjectsQuery,
  useCreateProjectMutation,
  useGetCurrentProjectQuery,
  useDeleteProjectMutation,
  useDelegateProjectMutation,
  useEditProjectMutation,
  useGetArchivedProjectsQuery,
  useGetCurrentArchProjectQuery,
  useEditArchProjectMutation,
  useGetProjectsStepsQuery,
  useChangeStepMutation,
  useGetCurrenciesQuery
} = projectsApi;
