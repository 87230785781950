import { useSearchParams } from 'react-router-dom';
import type { FormEventHandler } from 'react';
import { useCallback, useState } from 'react';
import { Transition } from 'react-transition-group';
import {
  useFireUserMutation,
  useGetCurrentUserQuery,
  useGetUserMeQuery
} from '../../../api/user-api';
import { CardLayout } from '../../../layouts/card-layout';
import { getApiError } from '../../../helpers/get-api-error';
import { Popup } from '../../../components/popup';
import type { OptionItem } from '../../../common/types/option-item';
import { useNotification } from '../../../hooks/use-notification';
import { CurrentUserSidebar } from './current-user-sidebar';
import { CurrentUserTabs } from './current-user-tabs';
import { EditUser } from './edit-user';
import {
  defaultContentStyle,
  defaultStyle,
  duration,
  transitionContentStyles,
  transitionStyles
} from './transition';
import { UserRating } from './user-rating';

interface CurrentUserProps {
  id: string | null;
}

export const CurrentUser = ({ id }: CurrentUserProps) => {
  const notification = useNotification();
  const { data: userMe } = useGetUserMeQuery('');
  const [edit, setEdit] = useState(false);
  const [rateDischarged, setRateDischarged] = useState<OptionItem>({
    id: '5',
    title: '5'
  });
  const [showUserRait, setShowUserRait] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [fireUser] = useFireUserMutation();
  const { data, error } = useGetCurrentUserQuery(
    { id },
    { refetchOnMountOrArgChange: true, skip: !id }
  );

  const handleClose = useCallback(() => {
    searchParams.delete('id');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleConfirmFired: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setShowUserRait(false);
    fireUser({
      user_id: id || '',
      discharged_user: true,
      discharge_rate: +rateDischarged.id
    })
      .unwrap()
      .then(() => {
        searchParams.delete('id');
        setSearchParams(searchParams);
      })
      .catch((err) => {
        notification({
          type: 'error',
          message: getApiError(err),
          title: ' Помилка'
        });
      });
  };

  const canView = userMe?.role?.view_user || userMe?.is_superuser;

  if (!userMe || !canView) {
    return null;
  }

  return (
    <Transition in={!!id} timeout={duration} unmountOnExit mountOnEnter>
      {(state) => (
        <CardLayout
          onClose={handleClose}
          sidebar={<CurrentUserSidebar setEdit={setEdit} user={data} />}
          transitionStyle={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
          transitionContentStyle={{
            ...defaultContentStyle,
            ...transitionContentStyles[state]
          }}
        >
          {error ? (
            getApiError(error)
          ) : (
            <CurrentUserTabs
              userId={id || ''}
              files={data?.files}
              innFiles={data?.inn_files}
              passportFiles={data?.passport_files}
              currentSteps={data?.current_steps || []}
              tasksCount={data?.tasks.length || 0}
              projectsCount={data?.projects.length || 0}
            />
          )}
          {!!data && id && id === `${data.id}` && (
            <EditUser
              edit={edit}
              setEdit={setEdit}
              user={data}
              setShowUserRait={setShowUserRait}
            />
          )}
          <Popup
            show={showUserRait}
            setShow={setShowUserRait}
            title="Оцінка співробітника"
            maxContentWidth="448px"
            customPosition
          >
            <UserRating
              setShow={setShowUserRait}
              onSubmit={handleConfirmFired}
              rateDischarged={rateDischarged}
              setRateDischarged={setRateDischarged}
            />
          </Popup>
        </CardLayout>
      )}
    </Transition>
  );
};
