import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { scrollBar } from '../../../../style/helpers';

export const TabsStyled = styled.div`
  padding: 0 20px;
  width: 100%;
  max-width: 795px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const TabContent = styled.div`
  margin-top: 40px;
  height: calc(100vh - 140px);
  ${scrollBar}
`;

export const Info = styled.div`
  padding: 24px;
  margin-top: 20px;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.05), 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
`;

export const InfoTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${(p) => p.theme.colors.blue.dark};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoLink = styled(Link)`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${(p) => p.theme.colors.blue.primary};
`;

export const InfoItem = styled.div`
  margin-top: 20px;
  color: ${(p) => p.theme.colors.slate};
`;
