import { useState, type Dispatch, type SetStateAction } from 'react';
import { useChangeFields } from '../../../hooks/use-change-fields';
import type { OptionItem } from '../../../common/types/option-item';
import { useNotification } from '../../../hooks/use-notification';
import {
  dateToApiFromInput,
  dateToUIFormat,
  isDatePassed,
  isValidNewDate
} from '../../../helpers/date-helpers';
import { useCreateTaskMutation } from '../../../api/task-api';
import { getApiError } from '../../../helpers/get-api-error';
import { TaskForm } from '../task-form';

interface AddTaskProps {
  setShow: Dispatch<SetStateAction<boolean>>;
  initialProject?: OptionItem;
}

interface TextFields {
  name: string;
  description: string;
}

export const AddTask = ({ setShow, initialProject }: AddTaskProps) => {
  const notification = useNotification();
  const [highPriority, setHighPriority] = useState(false);
  const { fields, handleFieldsChange } = useChangeFields<TextFields>({
    name: '',
    description: ''
  });
  const [responsible, setResponsible] = useState<OptionItem[]>([
    {
      id: '',
      title: ''
    }
  ]);
  const [project, setProject] = useState<OptionItem>(
    initialProject || { id: '', title: '' }
  );
  const [date, setDate] = useState(dateToUIFormat(new Date()));

  const [createTask, { isLoading }] = useCreateTaskMutation();

  const handleSubmit = () => {
    let status: string | undefined = undefined;
    if (
      isValidNewDate(dateToApiFromInput(date)) &&
      isDatePassed(dateToApiFromInput(date))
    ) {
      status = '5';
    }
    createTask({
      resp_managers: Array.from(
        new Set(responsible.map((item) => item.id).filter((item) => item))
      ),
      title: fields.name,
      description: fields.description,
      high_priority: highPriority,
      deadline: dateToApiFromInput(date),
      project: project.id,
      status
    })
      .unwrap()
      .then(() => {
        notification({
          title: 'Завдання створено',
          message: 'Ви успішно створили нове завдання',
          type: 'success'
        });
        setShow(false);
      })
      .catch((err) => {
        notification({
          title: 'Помилка',
          message: getApiError(err),
          type: 'error'
        });
      });
  };

  return (
    <TaskForm
      fields={fields}
      handleFieldsChange={handleFieldsChange}
      responsible={responsible}
      setResponsible={setResponsible}
      project={project}
      setShow={setShow}
      highPriority={highPriority}
      setHighPriority={setHighPriority}
      date={date}
      setDate={setDate}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      setProject={setProject}
    />
  );
};
