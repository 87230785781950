import type { HeaderItem } from '../../../components/data-table';
import type { UsersTable } from './types';

export const tableHeaders: HeaderItem<UsersTable>[] = [
  { id: 'id', title: 'ID' },
  { id: 'full_name', title: 'ПІП' },
  { id: 'role', title: 'РОЛЬ CRM' },
  { id: 'phone', title: 'телефон' },
  { id: 'email', title: 'EMAIL' },
  { id: 'passport', title: 'ПАСПОРТ' },
  { id: 'inn', title: 'ІПН' },
  { id: 'username', title: 'логін' },
  { id: 'address', title: 'Адреса Проживання' },
  { id: 'address_reg', title: 'Адреса реєстрації' }
];

export const tableData = [
  {
    id: 1,
    full_name: 'Сон Іван Боричович',
    role: 'Адміністратор',
    organization: 'Фонд',
    phone: '+3805010112214',
    email: 'ivan@gmail.com',
    login: 'son.ivan',
    password: 'son23',
    address: 'Україна, Львівська обл., м. Львів, вул. Шухевича 5, кв. 5',
    address_reg: 'Україна, Львівська обл., м. Львів, вул. Шухевича 5, кв. 5'
  },
  {
    id: 2,
    full_name: 'Сон Іван Боричович',
    role: 'Адміністратор',
    organization: 'Фонд',
    phone: '+3805010112214',
    email: 'ivan@gmail.com',
    login: 'son.ivan',
    password: 'son23',
    address: 'Україна, Львівська обл., м. Львів, вул. Шухевича 5, кв. 5',
    address_reg: 'Україна, Львівська обл., м. Львів, вул. Шухевича 5, кв. 5'
  },
  {
    id: 3,
    full_name: 'Сон Іван Боричович',
    role: 'Адміністратор',
    organization: 'Фонд',
    phone: '+3805010112214',
    email: 'ivan@gmail.com',
    login: 'son.ivan',
    password: 'son23',
    address: 'Україна, Львівська обл., м. Львів, вул. Шухевича 5, кв. 5',
    address_reg: 'Україна, Львівська обл., м. Львів, вул. Шухевича 5, кв. 5'
  },
  {
    id: 4,
    full_name: 'Сон Іван Боричович',
    role: 'Адміністратор',
    organization: 'Фонд',
    phone: '+3805010112214',
    email: 'ivan@gmail.com',
    login: 'son.ivan',
    password: 'son23',
    address: 'Україна, Львівська обл., м. Львів, вул. Шухевича 5, кв. 5',
    address_reg: 'Україна, Львівська обл., м. Львів, вул. Шухевича 5, кв. 5'
  },
  {
    id: 5,
    full_name: 'Сон Іван Боричович',
    role: 'Адміністратор',
    organization: 'Фонд',
    phone: '+3805010112214',
    email: 'ivan@gmail.com',
    login: 'son.ivan',
    password: 'son23',
    address: 'Україна, Львівська обл., м. Львів, вул. Шухевича 5, кв. 5',
    address_reg: 'Україна, Львівська обл., м. Львів, вул. Шухевича 5, кв. 5'
  },
  {
    id: 6,
    full_name: 'Сон Іван Боричович',
    role: 'Адміністратор',
    organization: 'Фонд',
    phone: '+3805010112214',
    email: 'ivan@gmail.com',
    login: 'son.ivan',
    password: 'son23',
    address: 'Україна, Львівська обл., м. Львів, вул. Шухевича 5, кв. 5',
    address_reg: 'Україна, Львівська обл., м. Львів, вул. Шухевича 5, кв. 5'
  },
  {
    id: 7,
    full_name: 'Сон Іван Боричович',
    role: 'Адміністратор',
    organization: 'Фонд',
    phone: '+3805010112214',
    email: 'ivan@gmail.com',
    login: 'son.ivan',
    password: 'son23',
    address: 'Україна, Львівська обл., м. Львів, вул. Шухевича 5, кв. 5',
    address_reg: 'Україна, Львівська обл., м. Львів, вул. Шухевича 5, кв. 5'
  }
];
