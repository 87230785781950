import type { Dispatch, SetStateAction } from 'react';
import { AccordionBlock } from '../../../../components/accordion-block';
import { AccordionItem } from '../../../../components/accordion-item';
import { EditBtn } from '../../../../components/edit-btn';
import { UserInfo } from '../../../../components/user-info';
import type { User } from '../../../../models/user';
import { concatenateAddress } from '../../../../helpers/concatenate-address';
import { useGetUserMeQuery } from '../../../../api/user-api';
import {
  HeaderItem,
  UserId,
  UserSidebarHeader,
  UserSidebarStyled
} from './style';
import { SidebarScrollBlock } from './sidebar-scroll-block';

interface CurrentUserSidebarProps {
  user?: User;
  setEdit: Dispatch<SetStateAction<boolean>>;
}

export const CurrentUserSidebar = ({
  user,
  setEdit
}: CurrentUserSidebarProps) => {
  const { data: userMe } = useGetUserMeQuery('');

  if (!userMe) {
    return null;
  }

  const canEdit = userMe.role?.change_user || userMe.is_superuser;

  return (
    <UserSidebarStyled>
      {!!user && (
        <>
          {' '}
          <UserSidebarHeader>
            <HeaderItem>
              <UserInfo
                lastName={user.last_name}
                firstName={user.first_name}
                patronymic={user.patronymic}
              />
              {canEdit && <EditBtn onClick={() => setEdit(true)} />}
            </HeaderItem>
            <HeaderItem>
              <UserId>ID Співробітника: {user.id}</UserId>
            </HeaderItem>
          </UserSidebarHeader>
          <SidebarScrollBlock>
            <AccordionBlock maxHeight={900} title="Персональні дані">
              <AccordionItem title="Організація" data={user.organization} />
              <AccordionItem title="Прізвище" data={user.last_name} />
              <AccordionItem title="Ім’я" data={user.first_name} />
              <AccordionItem title="По батькові" data={user.patronymic} />
              <AccordionItem title="Гендер" data={user.gender.title} />
              <AccordionItem title="Телефон" data={user.phone} />
              <AccordionItem title="Email" data={user.email} />
              <AccordionItem
                title="Адреса проживання"
                data={concatenateAddress({
                  country: user.liv_country,
                  city: user.liv_city,
                  region: user.liv_region,
                  street: user.liv_street,
                  building: user.liv_building,
                  unit: user.liv_unit,
                  apartment: user.liv_apartment
                })}
              />
              <AccordionItem
                title="Адреса реєстрації"
                data={concatenateAddress({
                  country: user.reg_country,
                  city: user.reg_city,
                  region: user.reg_region,
                  street: user.reg_street,
                  building: user.reg_building,
                  unit: user.reg_unit,
                  apartment: user.reg_apartment
                })}
              />
              <AccordionItem title="Паспорт" data={user.passport} />
              <AccordionItem title="ІПН" data={user.id_number} />
            </AccordionBlock>
            <AccordionBlock maxHeight={130} title="Дані CRM">
              <AccordionItem title="Роль CRM" data={user.role?.name || ''} />
              <AccordionItem title="Логін" data={user.username} />
            </AccordionBlock>
          </SidebarScrollBlock>
        </>
      )}
    </UserSidebarStyled>
  );
};
