import { LoaderSmall } from '../loader-small';
import { LoaderWrapper } from './style';

interface LoaderProps {
  withoutOverlay?: boolean;
}

export const Loader = ({ withoutOverlay }: LoaderProps) => (
  <LoaderWrapper $withoutOverlay={withoutOverlay}>
    <LoaderSmall />
  </LoaderWrapper>
);
