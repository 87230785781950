import { useState, type FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetTasksQuery } from '../../../../api/task-api';
import { BtnPrimary } from '../../../../components/btn-primary';
import { Popup } from '../../../../components/popup';
import { AddTask } from '../../../tasks/add-task';
import { LoaderSmall } from '../../../../components/loader-small';
import { TASK_STATUSES } from '../../../../common/constants';
import { useGetUserMeQuery } from '../../../../api/user-api';
import {
  CreateTask,
  Description,
  TaskItem,
  TasksContainer,
  TasksStyled,
  TaskStatus,
  TaskTitle
} from './style';

interface ProjectTasksProps {
  projectId: string | number;
  projectTitle: string;
}

export const ProjectTasks: FC<ProjectTasksProps> = ({
  projectId,
  projectTitle
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAddTask, setShowAddTask] = useState(false);

  const { data: userMe } = useGetUserMeQuery('');

  const { data, isLoading, isFetching } = useGetTasksQuery(
    { params: `?project=${projectId}` },
    { refetchOnMountOrArgChange: true }
  );

  const handelOpenTask = (taskId: string | number) => {
    searchParams.set('task_id', `${taskId}`);
    searchParams.delete('project_id');
    setSearchParams(searchParams);
  };

  const canViewTask = userMe?.role?.view_task || userMe?.is_superuser;
  const canAddTask = userMe?.role?.add_task || userMe?.is_superuser;

  return (
    <TasksStyled>
      <CreateTask>
        {canAddTask && (
          <BtnPrimary onClick={() => setShowAddTask(true)}>
            Створити завдання
          </BtnPrimary>
        )}
      </CreateTask>
      {canViewTask && (
        <TasksContainer>
          {(isLoading || isFetching) && <LoaderSmall ac />}
          {!isFetching &&
            !isLoading &&
            data?.results.map((item) => (
              <TaskItem key={item.id}>
                <TaskTitle onClick={() => handelOpenTask(item.id)}>
                  {item.title}
                  <TaskStatus
                    style={{
                      color: '#fff',
                      fontWeight: 400,
                      background:
                        TASK_STATUSES.find(
                          (status) => status.id === item.status
                        )?.restFields?.color ||
                        TASK_STATUSES[0].restFields?.color
                    }}
                  >
                    {
                      TASK_STATUSES.find((status) => status.id === item.status)
                        ?.title
                    }
                  </TaskStatus>
                </TaskTitle>
                <Description>{item.description}</Description>
              </TaskItem>
            ))}
        </TasksContainer>
      )}
      {setShowAddTask && (
        <Popup
          title="Нове завдання"
          show={showAddTask}
          setShow={setShowAddTask}
          customPosition
        >
          <AddTask
            initialProject={{ id: projectId, title: projectTitle }}
            setShow={setShowAddTask}
          />
        </Popup>
      )}
    </TasksStyled>
  );
};
