import type { Dispatch, FC, SetStateAction } from 'react';
import { useState } from 'react';
import type { OptionItem } from '../../../../common/types/option-item';
import { activityTabs } from '../current-project-tabs/data';
import { Notes } from '../../../../components/notes';
import type { Note } from '../../../../models/note';
import type {
  DeleteNote,
  EditNote
} from '../../../../common/types/rtk-triggers/note';
import { ActivityStyled } from '../../../../components/activity/style';
import { ActivityTabs } from '../../../../components/activity/activity-tabs';
import { ProjectTasks } from '../tasks';

interface ActivityProps {
  notes: Note[];
  editNote: EditNote;
  deleteNote: DeleteNote;
  setShowNewNote: Dispatch<SetStateAction<boolean>>;
  projectId: string | number;
  projectTitle: string;
}

export const Activity: FC<ActivityProps> = ({
  notes,
  editNote,
  deleteNote,
  setShowNewNote,
  projectId,
  projectTitle
}) => {
  const [currentTab, setCurrentTab] = useState<OptionItem>(activityTabs[0]);

  const renderContent = () => {
    switch (currentTab.id) {
      case 'note':
        return (
          <Notes
            notes={notes}
            editNote={editNote}
            deleteNote={deleteNote}
            setShowNewNote={setShowNewNote}
          />
        );
      case 'task':
        return (
          <ProjectTasks projectTitle={projectTitle} projectId={projectId} />
        );

      default:
        return null;
    }
  };

  return (
    <ActivityStyled>
      <ActivityTabs
        tabs={activityTabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      {renderContent()}
    </ActivityStyled>
  );
};
