import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { useSearchParams } from 'react-router-dom';
import { useScrollHeight } from '../../hooks/use-scroll-height';
import { KanbanCard } from '../kanban-card';
import { StringCutter } from '../string-cutter';
import { useGetProjectsQuery } from '../../api/projects-api';
import { KANBAN_PAGE_SIZE } from '../../pages/projects/kanban';
import { filterQueryParams } from '../../helpers/filter-query-params';
import {
  ColHeader,
  ColStyled,
  DragPlaceholder,
  KanbanColStyled,
  LoaderStyled,
  TitleWrapper
} from './style';

interface KanbanColProps {
  title: string;
  idx: number;
  stepId: string | number;
  sizes: Record<string | number, number>;
  setSizes: Dispatch<SetStateAction<Record<string | number, number>>>;
  currentStep?: string | number;
  isChangingStep?: boolean;
  canEdit: boolean;
}

export const KanbanCol = ({
  title,
  idx,
  stepId,
  setSizes,
  sizes,
  currentStep,
  isChangingStep,
  canEdit
}: KanbanColProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const { scrollHeight, hasScrollBar, manuallySetScrollHeight } =
    useScrollHeight(ref);

  const { setNodeRef, isOver, over } = useDroppable({
    id: stepId || ''
  });

  const {
    data: projects,
    isFetching,
    isLoading
  } = useGetProjectsQuery(
    {
      params: `?size=${
        sizes[stepId] || KANBAN_PAGE_SIZE
      }&current_step=${stepId}&${filterQueryParams(searchParams.toString(), [
        'contact_id',
        'project_id',
        'current_step',
        'task_id',
        'page',
        'size'
      ])}`
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];

      if (target.isIntersecting) {
        setSizes((state) => ({
          ...state,
          [stepId]: state[stepId] + KANBAN_PAGE_SIZE
        }));
      }
    },
    [setSizes, stepId]
  );

  useEffect(() => {
    const loader = loaderRef.current;
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0.25
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader) {
      observer.observe(loader);
    }

    return () => {
      if (loader) {
        observer.unobserve(loader);
      }
    };
  }, [handleObserver, loaderRef]);

  useEffect(() => {
    manuallySetScrollHeight();
  }, [isOver, manuallySetScrollHeight]);

  const hasMore =
    projects && projects.results.length < projects.count && !isChangingStep;

  return (
    <KanbanColStyled>
      <ColHeader idx={idx}>
        <TitleWrapper>
          <StringCutter lines="1">{title}</StringCutter>
        </TitleWrapper>
        <span> ({projects?.count || 0})</span>
      </ColHeader>
      <ColStyled
        ref={ref}
        style={{
          height: `${scrollHeight - 8}px`,
          paddingRight: hasScrollBar ? '4px' : '10px'
        }}
      >
        <div style={{ minHeight: '90%' }} ref={setNodeRef}>
          {isOver && currentStep !== over?.id && <DragPlaceholder />}
          {projects?.results.map((item) => (
            <KanbanCard
              key={item.id}
              project={item}
              colIdx={idx}
              isFetching={isChangingStep || isFetching}
              canEdit={canEdit}
            />
          ))}
          <div
            ref={loaderRef}
            style={{
              minHeight: '5px',
              display: hasMore ? 'block' : 'none'
            }}
          >
            <LoaderStyled />
          </div>
          {(isFetching || isLoading) && !hasMore && <LoaderStyled />}
        </div>
      </ColStyled>
    </KanbanColStyled>
  );
};
