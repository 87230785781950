import { useEffect, useState } from 'react';
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { useSearchParams } from 'react-router-dom';
import type { OptionItem } from '../../../common/types/option-item';
import { useNotification } from '../../../hooks/use-notification';
import { filterQueryParams } from '../../../helpers/filter-query-params';
import type { Task } from '../../../models/task';
import { useChangeTaskStepMutation } from '../../../api/task-api';
import { KanbanCard } from '../kanban-card';
import { KanbanCol } from '../kanban-col';
import { KanbanStyled } from './style';

export const KANBAN_PAGE_SIZE = 25;

interface KanbanProps {
  stepsCount: number;
  steps: OptionItem<{ color?: string }>[];
  canEdit: boolean;
}

export const Kanban = ({ stepsCount, steps, canEdit }: KanbanProps) => {
  const notification = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTask, setCurrentTask] = useState<Task | null>(null);
  const [sizes, setSizes] = useState<Record<string | number, number>>(
    Object.fromEntries(steps.map((item) => [item.id, KANBAN_PAGE_SIZE]))
  );

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10
    }
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      distance: 10
    }
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const [changeStep, { isLoading }] = useChangeTaskStepMutation();

  const handleDragStart = (e: DragStartEvent) => {
    const draggingTask = e.active.data
      ? (e.active.data.current as Task)
      : undefined;
    if (draggingTask) {
      setCurrentTask(draggingTask);
    }
  };

  const handleDragEnd = (e: DragEndEvent) => {
    const draggingTask = e.active.data
      ? (e.active.data.current as Task)
      : undefined;
    if (draggingTask && e.over?.id && draggingTask.status) {
      const cardId = e.active.id;
      const prevColId = draggingTask.status;
      const nextColId = e.over?.id;
      const prevSize = prevColId && sizes[prevColId];
      const nextSize = nextColId && sizes[nextColId];
      if (prevColId !== nextColId) {
        changeStep({
          taskId: cardId,
          status: nextColId as number,
          params: `?size=${nextSize}&status=${nextColId}&${filterQueryParams(
            searchParams.toString(),
            [
              'contact_id',
              'project_id',
              'task_id',
              'status',
              'current_step',
              'page',
              'size'
            ]
          )}`,
          prevParams: `?size=${prevSize}&status=${
            draggingTask.status
          }&${filterQueryParams(searchParams.toString(), [
            'contact_id',
            'project_id',
            'task_id',
            'status',
            'current_step',
            'page',
            'size'
          ])}`,
          task: draggingTask
        })
          .unwrap()
          .catch(() => {
            notification({
              type: 'error',
              title: 'Помилка',
              message: 'Не вдалося перемістити, спробуйте ще раз'
            });
          });
      }
    }
    setCurrentTask(null);
  };

  useEffect(() => {
    if (searchParams.has('status') || searchParams.has('page')) {
      searchParams.delete('status');
      searchParams.delete('page');
      setSearchParams(searchParams);
    }
    if (searchParams.has('page')) {
      searchParams.delete('page');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return (
    <KanbanStyled colCount={stepsCount}>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        {steps.map((item, i) => (
          <KanbanCol
            sizes={sizes}
            setSizes={setSizes}
            key={item.id}
            title={item.title}
            idx={i}
            stepId={item.id}
            isChangingStep={isLoading || !!currentTask}
            currentStep={currentTask?.status}
            color={item.restFields?.color || ''}
            canEdit={canEdit}
          />
        ))}
        <DragOverlay>
          {currentTask?.id && (
            <KanbanCard
              colIdx={steps.findIndex((item) => item.id === currentTask.status)}
              task={currentTask}
              canEdit={canEdit}
            />
          )}
        </DragOverlay>
      </DndContext>
    </KanbanStyled>
  );
};
