import { useEffect, useState } from 'react';
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { useSearchParams } from 'react-router-dom';
import type { OptionItem } from '../../../common/types/option-item';
import { useNotification } from '../../../hooks/use-notification';
import type { Project } from '../../../models/project';
import { useChangeStepMutation } from '../../../api/projects-api';
import { KanbanCol } from '../../../components/kanban-col';
import { KanbanCard } from '../../../components/kanban-card';
import { filterQueryParams } from '../../../helpers/filter-query-params';
import { KanbanStyled } from './style';

export const KANBAN_PAGE_SIZE = 25;

interface KanbanProps {
  stepsCount: number;
  steps: OptionItem[];
  canEdit: boolean;
}

export const Kanban = ({ stepsCount, steps, canEdit }: KanbanProps) => {
  const notification = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentProject, setCurrentProject] = useState<Project | null>(null);
  const [sizes, setSizes] = useState<Record<string | number, number>>(
    Object.fromEntries(steps.map((item) => [item.id, KANBAN_PAGE_SIZE]))
  );

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10
    }
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      distance: 10
    }
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const [changeStep, { isLoading }] = useChangeStepMutation();

  const handleDragStart = (e: DragStartEvent) => {
    const draggingProject = e.active.data
      ? (e.active.data.current as Project)
      : undefined;
    if (draggingProject) {
      setCurrentProject(draggingProject);
    }
  };

  const handleDragEnd = (e: DragEndEvent) => {
    const draggingProject = e.active.data
      ? (e.active.data.current as Project)
      : undefined;
    if (draggingProject && e.over?.id && draggingProject.current_step) {
      const cardId = e.active.id;
      const prevColId = draggingProject.current_step.id;
      const nextColId = e.over?.id;
      const prevSize = prevColId && sizes[prevColId];
      const nextSize = nextColId && sizes[nextColId];
      if (prevColId !== nextColId) {
        changeStep({
          projectId: cardId,
          step: {
            id: nextColId,
            title: steps.find((item) => item.id === nextColId)?.title || ''
          },
          params: `?size=${nextSize}&current_step=${nextColId}&${filterQueryParams(
            searchParams.toString(),
            [
              'contact_id',
              'project_id',
              'current_step',
              'task_id',
              'page',
              'size'
            ]
          )}`,
          prevParams: `?size=${prevSize}&current_step=${
            draggingProject.current_step.id
          }&${filterQueryParams(searchParams.toString(), [
            'contact_id',
            'project_id',
            'current_step',
            'task_id',
            'page',
            'size'
          ])}`,
          project: draggingProject
        })
          .unwrap()
          .catch(() => {
            notification({
              type: 'error',
              title: 'Помилка',
              message: 'Не вдалося перемістити, спробуйте ще раз'
            });
          });
      }
    }
    setCurrentProject(null);
  };

  useEffect(() => {
    if (searchParams.has('current_steps') || searchParams.has('page')) {
      searchParams.delete('current_steps');
      searchParams.delete('page');
      setSearchParams(searchParams);
    }
    if (searchParams.has('page')) {
      searchParams.delete('page');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return (
    <KanbanStyled colCount={stepsCount}>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        {steps.map((item, i) => (
          <KanbanCol
            sizes={sizes}
            setSizes={setSizes}
            key={item.id}
            title={item.title}
            idx={i}
            stepId={item.id}
            isChangingStep={isLoading || !!currentProject}
            currentStep={currentProject?.current_step?.id}
            canEdit={canEdit}
          />
        ))}
        <DragOverlay>
          {currentProject?.id && (
            <KanbanCard
              colIdx={steps.findIndex(
                (item) => item.id === currentProject?.current_step?.id
              )}
              project={currentProject}
              canEdit={canEdit}
            />
          )}
        </DragOverlay>
      </DndContext>
    </KanbanStyled>
  );
};
