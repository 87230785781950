import styled from 'styled-components';
import { scrollBar } from '../../../../style/helpers';

export const TasksStyled = styled.div`
  padding: 20px 0;
`;

export const CreateTask = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
`;

export const TasksContainer = styled.div`
  padding: 0 20px;
  height: calc(100vh - 250px);
  ${scrollBar}
`;

export const TaskItem = styled.div`
  padding: 24px;
  margin-top: 20px;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.05), 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
`;

export const TaskTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${(p) => p.theme.colors.blue.dark};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const TaskStatus = styled.div`
  padding: 5px 20px;
  border-radius: 5px;
  color: '#ffffff';
`;

export const Description = styled.div`
  margin-top: 20px;
`;
