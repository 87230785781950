import { Route, Routes } from 'react-router-dom';
import { LogIn } from './pages/log-in';
import { AppRoute, UsersRoute } from './common/enums/app/app-route.enum';
import { UsersPage } from './pages/users';
import { ContactsPage } from './pages/contacts';
import { UsersData } from './pages/users/users-data';
import { UserRoles } from './pages/users/user-roles';
import { UsersAccess } from './pages/users/users-access';
import { PublickRoute } from './components/public-route';
import { PrivateRoute } from './components/private-route';
import { ForgotPassword } from './pages/forgot-password';
import { ForgotPasswordRequest } from './pages/forgot-password-request';
import { NotFoundPage } from './pages/not-found';
import { TasksPage } from './pages/tasks';
import { ProjectsPage } from './pages/projects';

export const App = () => (
  <Routes>
    <Route
      path={AppRoute.LOG_IN}
      element={<PublickRoute component={LogIn} />}
    />
    <Route
      path={AppRoute.FORGOT_PASSWORD}
      element={<PublickRoute component={ForgotPassword} />}
    />
    <Route
      path={AppRoute.FORGOT_PASSWORD_REQUEST}
      element={<PublickRoute component={ForgotPasswordRequest} />}
    />
    <Route
      path={AppRoute.USERS}
      element={<PrivateRoute component={UsersPage} />}
    >
      <Route path={UsersRoute.ROLES} element={<UserRoles />} />
      <Route path={UsersRoute.ACCESS} element={<UsersAccess />} />
      <Route index element={<UsersData />} />
    </Route>
    <Route
      path={AppRoute.PARTNERS}
      element={<PrivateRoute component={ContactsPage} />}
    />
    <Route
      path={AppRoute.PROJECTS}
      element={<PrivateRoute component={ProjectsPage} />}
    />
    <Route
      path={AppRoute.CONTACTS}
      element={<PrivateRoute component={ContactsPage} />}
    />
    <Route
      path={AppRoute.TASKS}
      element={<PrivateRoute component={TasksPage} />}
    />
    <Route
      path={AppRoute.ROOT}
      element={<PrivateRoute component={TasksPage} />}
    />
    <Route
      path={AppRoute.ANY}
      element={<PrivateRoute component={NotFoundPage} />}
    />
  </Routes>
);
