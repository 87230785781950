import { concatenateAddress } from '../../../helpers/concatenate-address';
import type { User } from '../../../models/user';
import type { UsersTable } from './types';

export const transformUserData = (data?: User[]): UsersTable[] =>
  data?.map((item) => ({
    id: item.id,
    full_name: <p style={{ whiteSpace: 'nowrap' }}>{item.full_name}</p>,
    role: item.role?.name || '',
    phone: item.phone,
    email: item.email,
    passport: item.passport,
    inn: item.id_number,
    username: item.username,
    address: concatenateAddress({
      country: item.liv_country,
      city: item.liv_city,
      region: item.liv_region,
      street: item.liv_street,
      building: item.liv_building,
      unit: item.liv_unit,
      apartment: item.liv_apartment
    }),
    address_reg: concatenateAddress({
      country: item.reg_country,
      city: item.reg_city,
      region: item.reg_region,
      street: item.reg_street,
      building: item.reg_building,
      unit: item.reg_unit,
      apartment: item.reg_apartment
    })
  })) || [];
