import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ButtonAdd } from '../../components/button-add';
import { Counter } from '../../components/counter';
import { FlexContainer } from '../../components/flex-container';
import { PageTitle } from '../../components/page-title';
import { SubHeader } from '../../components/subheader';
import { DataTable } from '../../components/data-table';
import { Popup } from '../../components/popup';
import {
  useDeleteTaskMutation,
  useEditTaskMutation,
  useGetTasksQuery
} from '../../api/task-api';
import { NoData } from '../../components/no-data';
import { useTypedSelector } from '../../api/store';
import { useScrollHeight } from '../../hooks/use-scroll-height';
import { dateTableFormat, isDatePassed } from '../../helpers/date-helpers';
import { TableSelect } from '../../components/table-select';
import { TASK_STATUSES } from '../../common/constants';
import { useNotification } from '../../hooks/use-notification';
import { getApiError } from '../../helpers/get-api-error';
import { CheckBox } from '../../components/checkbox';
import { ProjectsSvg } from '../../components/svg/sidebar/projects-svg';
import { ConfirmPopup } from '../../components/confirm-popup';
import { useFilter } from '../../hooks/use-filter';
import { SubheaderSelect } from '../../components/subheader-select';
import { Filters } from '../../components/filters';
import { FilterSvg } from '../../components/svg/filter-svg';
import { StringCutter } from '../../components/string-cutter';
import { SwitcherKanban } from '../../components/switcher-kanban';
import { useGetUserMeQuery } from '../../api/user-api';
import { AddTask } from './add-task';
import { initialFilters, tableHeaders } from './data';
import type { TaskTable } from './types';
import { Kanban } from './kanban';

export const TasksPage = () => {
  const tableRef = useRef(null);
  const { data: userMe } = useGetUserMeQuery('');
  const notification = useNotification();
  const { scrollHeight } = useScrollHeight(tableRef);
  const { pageSize } = useTypedSelector((state) => state['page-data']);
  const [searchParams, setSearchParams] = useSearchParams();
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [showAddTask, setShowAddTask] = useState(false);
  const [showConfirmDelete, setShowConfimDelete] = useState(false);
  const {
    filters,
    setFilters,
    showFilter,
    setShowFilter,
    filtersLength,
    badges,
    resetFilters,
    aplyFilter
  } = useFilter({
    initialFilters,
    usersFilterName: 'resp_managers',
    projectsFilterName: 'project'
  });

  const [deleteTask] = useDeleteTaskMutation();

  const { data: tasks } = useGetTasksQuery(
    {
      params: `?size=${pageSize}&${searchParams.toString()}`
    },
    { refetchOnMountOrArgChange: true }
  );
  const [editTask] = useEditTaskMutation();

  const handleDelete = () => {
    deleteTask({ ids: checkedList })
      .unwrap()
      .then(() => {
        notification({
          title: 'Завдання видалено!',
          message: 'Ви успішно видалили обрані завдання.',
          type: 'success'
        });
        setShowConfimDelete(false);
        setCheckedList([]);
      })
      .catch((err) => {
        notification({
          title: 'Помилка',
          message: getApiError(err),
          type: 'error'
        });
      });
  };

  const handleChangeStatus = (id: string | number, status: string | number) => {
    editTask({ id, body: { status } })
      .unwrap()
      .catch((err) => {
        notification({
          title: 'Помилка',
          message: getApiError(err),
          type: 'error'
        });
      });
  };

  const handleChangePriority = (id: string | number, priority: boolean) => {
    editTask({ id, body: { high_priority: priority } })
      .unwrap()
      .catch((err) => {
        notification({
          title: 'Помилка',
          message: getApiError(err),
          type: 'error'
        });
      });
  };

  const handleSwitchKanban = (value: boolean) => {
    if (value) {
      searchParams.set('kanban', 'true');
      searchParams.delete('status');
    } else {
      searchParams.delete('kanban');
    }
    setSearchParams(searchParams);
  };

  const canView = userMe?.role?.view_task || userMe?.is_superuser;
  const canAdd = userMe?.role?.add_task || userMe?.is_superuser;
  const canDelete = userMe?.role?.delete_task || userMe?.is_superuser;
  const canEdit = userMe?.role?.change_task || userMe?.is_superuser;

  if (!canView) {
    return null;
  }

  return (
    <>
      <SubHeader mt="20px">
        <FlexContainer>
          <PageTitle>Завдання</PageTitle>
          <Counter icon={ProjectsSvg}>{tasks?.count || 0}</Counter>
          <SubheaderSelect
            show={showFilter}
            setShow={setShowFilter}
            icon={FilterSvg}
            title={`Розширені фільтри (${filtersLength})`}
            isActive={!!filtersLength}
          >
            <Filters
              setFilters={setFilters}
              filters={
                searchParams.has('kanban')
                  ? filters.filter((item) => item.name !== 'status')
                  : filters
              }
              setShow={setShowFilter}
              onCancel={resetFilters}
              onSubmit={aplyFilter}
              disabled={!filtersLength && !badges.length}
            />
          </SubheaderSelect>
          <SwitcherKanban
            isRightOption={searchParams.has('kanban')}
            setIsRightOptins={handleSwitchKanban}
          />
        </FlexContainer>
        {canAdd && (
          <ButtonAdd onClick={() => setShowAddTask(true)}>
            Нове завдання
          </ButtonAdd>
        )}
      </SubHeader>
      <div ref={tableRef}>
        {tasks && !tasks.results.length ? (
          <NoData subTitle="" />
        ) : searchParams.has('kanban') ? (
          <Kanban
            canEdit={!!canEdit}
            stepsCount={TASK_STATUSES.length}
            steps={TASK_STATUSES}
          />
        ) : (
          <DataTable<TaskTable>
            onRowClick={(id) => {
              searchParams.set('task_id', `${id}`);
              setSearchParams(searchParams);
            }}
            customHeight={
              (tasks?.count || 0) >= pageSize ? scrollHeight - 64 : scrollHeight
            }
            actions={[
              { title: 'Видалити', handler: () => setShowConfimDelete(true) }
            ]}
            count={tasks?.count || 0}
            headers={tableHeaders}
            data={
              tasks?.results.map((item, i) => ({
                id: +item.id,
                name: item.title,
                description: (
                  <StringCutter lines="2">{item.description}</StringCutter>
                ),
                project: item.project?.title || '',
                status: (
                  <TableSelect
                    disabled={
                      (item?.status === 5 &&
                        item.deadline &&
                        isDatePassed(item.deadline)) ||
                      !canEdit
                    }
                    initialValue={
                      TASK_STATUSES.find(
                        (status) => status.id === item.status
                      ) || TASK_STATUSES[0]
                    }
                    onSelect={(value) => {
                      handleChangeStatus(item.id, value.id);
                    }}
                    options={TASK_STATUSES}
                    color={
                      TASK_STATUSES.find((status) => status.id === item.status)
                        ?.restFields?.color ||
                      TASK_STATUSES[0].restFields?.color
                    }
                    isFixedPosition={tasks.results.length < 6}
                    openTop={
                      tasks.results.length > 5 && i > tasks.results.length - 4
                    }
                  />
                ),
                high_priority: (
                  <div onClick={(e) => e.stopPropagation()}>
                    <CheckBox
                      disabled={!canEdit}
                      checked={item.high_priority}
                      justifyCenter
                      setChecked={(checked) =>
                        handleChangePriority(item.id, checked)
                      }
                    />
                  </div>
                ),
                responsible: item.resp_managers.map((item) => (
                  <p key={item.id}>{item.full_name}</p>
                )),
                created_by: item.created_by.username,
                deadline: dateTableFormat(item.deadline),
                created_at: dateTableFormat(item.created_at)
              })) || []
            }
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            withOutHover
            withOutCheck={!canDelete}
          />
        )}
      </div>
      <Popup title="Нове завдання" show={showAddTask} setShow={setShowAddTask}>
        <AddTask setShow={setShowAddTask} />
      </Popup>
      <ConfirmPopup
        show={showConfirmDelete}
        title={
          checkedList.length === 1
            ? `Чи дійсно бажаєте видалити  завдання ${
                tasks?.results.find((item) => item.id === checkedList[0])
                  ?.title || ''
              }?`
            : `Чи дійсно бажаєте видалити ${checkedList.length} завдань`
        }
        setShow={setShowConfimDelete}
        onSubmit={handleDelete}
      />
    </>
  );
};
