import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EditBtn } from '../../../../components/edit-btn';
import { ActionBtn } from '../../../../components/action-btn';
import { ActionsRemoveSvg } from '../../../../components/svg/actions/actions-remove-svg';
import { useGetUserMeQuery } from '../../../../api/user-api';
import { ConfirmPopup } from '../../../../components/confirm-popup';
import { useNotification } from '../../../../hooks/use-notification';
import { getApiError } from '../../../../helpers/get-api-error';
import { AccordionBlock } from '../../../../components/accordion-block';
import { AccordionItem } from '../../../../components/accordion-item';
import type { Project } from '../../../../models/project';
import { useDeleteProjectMutation } from '../../../../api/projects-api';
import { dateTableFormat } from '../../../../helpers/date-helpers';
import { formatNumber } from '../../../../helpers/format-number';
import {
  ContactSidebarHeader,
  ContactSidebarStyled,
  FullName,
  HeaderItem
} from './style';
import { SidebarScrollBlock } from './sidebar-scroll-block';

interface CurrentProjectSidebarProps {
  project?: Project;
  setEdit: Dispatch<SetStateAction<boolean>>;
}

export const CurrentProjectSidebar = ({
  project,
  setEdit
}: CurrentProjectSidebarProps) => {
  const notification = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: userMe } = useGetUserMeQuery('');
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleteProject, { isLoading: deleteIsLoading }] =
    useDeleteProjectMutation();

  const handleDelete = () => {
    deleteProject({ ids: [project?.id || ''] })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Продажу видалено',
          message: 'Ви успішно видалили продажу'
        });
        searchParams.delete('id');
        searchParams.delete('project_id');
        setSearchParams(searchParams);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  if (!userMe) {
    return null;
  }

  const canEdit = userMe?.role?.change_project || userMe?.is_superuser;
  const canRemove = userMe.role?.delete_project || userMe.is_superuser;

  return (
    <ContactSidebarStyled>
      {!!project && (
        <>
          <ContactSidebarHeader>
            <HeaderItem>
              {canEdit && <EditBtn onClick={() => setEdit(true)} />}
              {canRemove && (
                <ActionBtn
                  onClick={() => setShowConfirmDelete(true)}
                  tooltip="Видалити"
                >
                  <ActionsRemoveSvg />
                </ActionBtn>
              )}
            </HeaderItem>
            <FullName>{project.title}</FullName>
          </ContactSidebarHeader>
          <SidebarScrollBlock>
            <AccordionBlock title="Контакт">
              <AccordionItem title="Назва" data={project.contact.title} />
              <AccordionItem title="Телефон" data={project.contact.phone} />
            </AccordionBlock>
            <AccordionBlock title="Деталі продажі">
              <AccordionItem
                title="Сума"
                data={formatNumber(project.budget || '')}
              />
              <AccordionItem
                title="Валюта"
                data={project.currency?.title || ''}
              />
              <AccordionItem
                title="Відповідальний"
                data={
                  <>
                    {project.resp_managers.map((item) => (
                      <div key={item.id}>{item.full_name || item.username}</div>
                    ))}
                  </>
                }
              />
              <AccordionItem title="Eтап" data={project.current_step?.title} />
              <AccordionItem
                title="Дедлайн"
                data={dateTableFormat(project.current_deadline || '')}
              />
              <AccordionItem title="Опис" data={project.description} />
            </AccordionBlock>
          </SidebarScrollBlock>
        </>
      )}
      <ConfirmPopup
        show={showConfirmDelete}
        setShow={setShowConfirmDelete}
        title={`Чи дійсно бажаєте видалити продажу ${project?.title}`}
        onSubmit={handleDelete}
        disabled={deleteIsLoading}
        needTranslate
      />
    </ContactSidebarStyled>
  );
};
