import styled, { css } from 'styled-components';

const withoutOverlayStyle = css`
  width: 60px;
  height: 60px;
  display: block;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
`;

export const LoaderWrapper = styled.div<{ $withoutOverlay?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  ${(p) => (p.$withoutOverlay ? withoutOverlayStyle : '')}
  z-index: 10;
`;
