import { useState } from 'react';
import { TabItem } from '../../../../components/tab-item';
import { PageFiles } from '../../../../components/page-files';
import type { User } from '../../../../models/user';
import type { ServerFile } from '../../../../common/types/server-file';
import { useEditUserMutation } from '../../../../api/user-api';
import { AppRoute } from '../../../../common/enums/app/app-route.enum';
import {
  Info,
  InfoItem,
  InfoLink,
  InfoTitle,
  TabContent,
  TabsStyled
} from './style';
import { tabList } from './data';

interface CurrentUserTabsProps {
  files?: ServerFile[];
  passportFiles?: ServerFile[];
  innFiles?: ServerFile[];
  userId: string | number;
  currentSteps: { id: string | number; title: string; count: number }[];
  tasksCount: number;
  projectsCount: number;
}

export const CurrentUserTabs = ({
  files,
  passportFiles,
  innFiles,
  userId,
  currentSteps,
  tasksCount,
  projectsCount
}: CurrentUserTabsProps) => {
  const [currentTab, setCurrentTab] = useState('views');
  const [editUser, { isLoading }] = useEditUserMutation();

  const transformProjectsCountLabel = (count: number) => {
    if (count === 1) {
      return 'Проект';
    }
    if (count > 1 && count < 5) {
      return 'Проекти';
    }

    return 'Проектів';
  };

  const renderContent = () => {
    switch (currentTab) {
      case 'views':
        return (
          <div style={{ padding: '0 20px' }}>
            <Info>
              <InfoTitle>
                Відповідальний за {projectsCount}{' '}
                {transformProjectsCountLabel(projectsCount)}
                <InfoLink to={`${AppRoute.PROJECTS}?resp_managers=${userId}`}>
                  Переглянути
                </InfoLink>
              </InfoTitle>
              <InfoItem>Відповідальний за такі етапи:</InfoItem>
              {currentSteps.map((item) => (
                <InfoItem key={item.id}>
                  {item.title}: {item.count}
                </InfoItem>
              ))}
              <InfoItem>Всього {tasksCount} завдань</InfoItem>
            </Info>
          </div>
        );
      case 'files':
        return (
          <PageFiles<User>
            contactId={userId}
            files={files}
            passportFiles={passportFiles}
            innFiles={innFiles}
            editTrigger={editUser}
            isLoading={isLoading}
            isDeleteLoading={isLoading}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TabsStyled>
        {tabList.map((item, i) => (
          <TabItem
            onClick={setCurrentTab}
            $isActive={item.id === currentTab}
            {...item}
            key={i}
          />
        ))}
      </TabsStyled>
      <TabContent>{renderContent()}</TabContent>
    </>
  );
};
