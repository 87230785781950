import type { FormEventHandler } from 'react';
import { useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { FormBtns } from '../../../components/form-btns';
import { useChangeFields } from '../../../hooks/use-change-fields';
import { Input } from '../../../components/input';
import { Select } from '../../../components/select';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import {
  useCreateProjectMutation,
  useEditProjectMutation,
  useGetCurrenciesQuery,
  useGetProjectsStepsQuery
} from '../../../api/projects-api';
import type { OptionItem } from '../../../common/types/option-item';
import { mapUsersToProps } from '../../../helpers/map-users-to-option';
import { useGetUsersQuery } from '../../../api/user-api';
import { IconContainer } from '../../../components/icon-container';
import { CloseEditSvg } from '../../../components/svg/close-edit-svg';
import { useGetContactsQuery } from '../../../api/contact-api';
import { TextArea } from '../../../components/text-area';
import { InputDate } from '../../../components/input-date';
import {
  dateToApiFromInput,
  dateToUIFormat,
  isValidNewDate
} from '../../../helpers/date-helpers';
import type { Project } from '../../../models/project';
import { formatNumber } from '../../../helpers/format-number';
import {
  AddProjectStyled,
  BudgetWrapper,
  DoubleRow,
  GroupTitle,
  InputsContainer,
  ManagerItem,
  Step,
  StepTitle
} from './style';

interface AddProjectProps {
  setShow: Dispatch<SetStateAction<boolean>>;
  project?: Project;
}

interface TextFields {
  title: string;
  description: string;
  budget: string;
}

export const AddProject = ({ setShow, project }: AddProjectProps) => {
  const notification = useNotification();
  const [responsible, setResponsible] = useState<OptionItem[]>([
    {
      id: '',
      title: ''
    }
  ]);
  const [saleType, setSaleType] = useState<OptionItem>({
    id: '',
    title: ''
  });
  const [termResponsible, setTermResponsible] = useState('');
  const [debounceResponsibleTerm, setDebounceResponsibleTerm] = useState('');
  const [contact, setContact] = useState<OptionItem>({ id: '', title: '' });
  const [termContact, setTermContact] = useState('');
  const [debounceContactTerm, setDebounceContactTerm] = useState('');
  const [deadlines, setDeadLines] = useState<
    Record<string, { date: string | null; responsible: OptionItem }>
  >({});
  const [currency, setCurrency] = useState<OptionItem>({ id: '', title: '' });

  const { fields, handleFieldsChange, updateFields } =
    useChangeFields<TextFields>({
      title: '',
      description: '',
      budget: ''
    });

  const { data: users, isFetching: isFetchingUsers } = useGetUsersQuery(
    { params: `?size=100&search=${debounceResponsibleTerm}` },
    { refetchOnMountOrArgChange: true }
  );
  const { data: contacts, isFetching: isFetchingContacts } =
    useGetContactsQuery(
      { params: `?size=100&search=${debounceContactTerm}` },
      { refetchOnMountOrArgChange: true }
    );
  const { data: steps } = useGetProjectsStepsQuery({ params: '' });
  const { data: currencies } = useGetCurrenciesQuery({ params: '' });

  const [createProject, { isLoading }] = useCreateProjectMutation();
  const [editProject, { isLoading: isLoadingEdit }] = useEditProjectMutation();

  useEffect(() => {
    if (project) {
      updateFields({
        title: project.title,
        description: project.description,
        budget: formatNumber(project.budget || '')
      });
      setContact(project.contact);
      setCurrency(project.currency || { id: '', title: '' });
      setSaleType(project.sale_type || { id: '', title: '' });
      setResponsible(
        project.resp_managers.length
          ? project.resp_managers.map((item) => ({
              id: item.id,
              title: item.full_name
            }))
          : [
              {
                id: '',
                title: ''
              }
            ]
      );
      setDeadLines(
        Object.fromEntries(
          project.step_deadlines.map((item) => [
            item.step.id,
            {
              date: item.date ? dateToUIFormat(new Date(item.date)) : null,
              responsible: {
                id: item.responsible?.id || '',
                title: item.responsible?.full_name || ''
              }
            }
          ])
        )
      );
    }
  }, [project, updateFields]);

  const notAllFieldsAreFilled = !fields.title;

  const handleDeleteManager = (i: number) => {
    if (responsible && responsible.length === 1) {
      setResponsible([{ id: '', title: '' }]);
    } else {
      setResponsible((state) => state.filter((_, j) => j !== i));
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const stepsDeadlines = Object.entries(deadlines)
      .filter((item) => item[1]?.date || item[1]?.responsible?.id)
      .map(([step_id, deadline]) => ({
        step_id,
        date:
          deadline.date && isValidNewDate(dateToApiFromInput(deadline.date))
            ? dateToApiFromInput(deadline.date)
            : null,
        responsible: deadline?.responsible?.id || ''
      }));

    if (project) {
      editProject({
        id: project.id,
        body: {
          title: fields.title,
          description: fields.description,
          budget: fields.budget.replaceAll(' ', '') || undefined,
          steps_deadlines: stepsDeadlines,
          contact: contact.id,
          sale_type: saleType.id,
          resp_managers: responsible.map((item) => item.id),
          currency: currency.id || undefined
        }
      })
        .unwrap()
        .then(() => {
          notification({
            type: 'success',
            message: 'Продажу змінено',
            title: 'Ви успішно змінили продажу'
          });
          setShow(false);
        })
        .catch((err) => {
          notification({
            type: 'error',
            message: 'Помилка',
            title: getApiError(err)
          });
        });

      return;
    }
    const currentStep = steps?.results && steps.results[0].id;
    createProject({
      title: fields.title,
      description: fields.description,
      budget: fields.budget.replaceAll(' ', '') || undefined,
      steps_deadlines: stepsDeadlines,
      contact: contact.id,
      sale_type: saleType.id,
      current_step: currentStep,
      current_resp:
        stepsDeadlines.length && stepsDeadlines[0].step_id == `${currentStep}`
          ? stepsDeadlines[0].responsible
          : undefined,
      resp_managers: responsible.map((item) => item.id),
      currency: currency.id || undefined
    })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          message: 'Продажу додано',
          title: 'Ви успішно створили продажу'
        });
        setShow(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          message: 'Помилка',
          title: getApiError(err)
        });
      });
  };

  return (
    <AddProjectStyled onSubmit={handleSubmit}>
      <InputsContainer>
        <Input
          label="Назва*"
          value={fields.title}
          name="title"
          onChange={handleFieldsChange}
          required
        />
        <Select
          label="Тип продажі*"
          value={saleType}
          setValue={setSaleType}
          options={[
            { id: 1, title: 'Продажі нові' },
            { id: 2, title: 'Продажі б/у' },
            { id: 3, title: 'Сервіс' }
          ]}
        />
        {responsible.map((item, i) => (
          <ManagerItem key={i}>
            <Select
              label="Відповідальний*"
              value={item}
              setValue={(value) =>
                setResponsible((state) =>
                  state.map((manager, j) => (i === j ? value : manager))
                )
              }
              options={mapUsersToProps(users?.results)}
              isLoading={isFetchingUsers}
              isSearch
              term={termResponsible}
              setTerm={setTermResponsible}
              setDebounceTerm={setDebounceResponsibleTerm}
            />
            {responsible.length === 1 && !responsible[0].id ? (
              ''
            ) : (
              <IconContainer
                m="0 0 11px 0"
                onClick={() => handleDeleteManager(i)}
              >
                <CloseEditSvg />
              </IconContainer>
            )}
          </ManagerItem>
        ))}
        <DoubleRow>
          <TextArea
            label="Опис"
            value={fields.description}
            name="description"
            onChange={handleFieldsChange}
          />
        </DoubleRow>
        <Select
          label="Контакт*"
          options={
            contacts?.results.map((item) => ({
              id: item.id,
              title: item.title
            })) || []
          }
          value={contact || { id: '', title: '' }}
          setValue={setContact}
          isLoading={isFetchingContacts}
          isSearch
          term={termContact}
          setTerm={setTermContact}
          setDebounceTerm={setDebounceContactTerm}
        />

        <BudgetWrapper>
          <Input
            label="Сума"
            value={fields.budget}
            name="budget"
            onChange={(e) =>
              updateFields((state) => ({
                ...state,
                budget: formatNumber(e.target.value)
              }))
            }
          />
          <Select
            label="Валюта"
            options={currencies?.results}
            value={currency}
            setValue={setCurrency}
            placeholder=""
          />
        </BudgetWrapper>
        <div></div>
        <GroupTitle>Етапи продажі:</GroupTitle>
        {steps?.results.map((item) => (
          <Step key={item.id}>
            <StepTitle>{item.title}</StepTitle>
            <InputDate
              label="Дедлайн"
              value={deadlines[item.id]?.date || ''}
              setValue={(date) =>
                setDeadLines((state) => ({
                  ...state,
                  [item.id]: { ...state[item.id], date }
                }))
              }
            />
            <Select
              label="Відповідальний"
              value={deadlines[item.id]?.responsible || { id: '', title: '' }}
              setValue={(value) =>
                setDeadLines((state) => ({
                  ...state,
                  [item.id]: { ...state[item.id], responsible: value }
                }))
              }
              options={mapUsersToProps(users?.results)}
              isLoading={isFetchingUsers}
              isSearch
              term={termResponsible}
              setTerm={setTermResponsible}
              setDebounceTerm={setDebounceResponsibleTerm}
            />
          </Step>
        ))}
      </InputsContainer>
      <FormBtns
        disabled={
          notAllFieldsAreFilled ||
          isLoading ||
          isLoadingEdit ||
          !responsible.filter((item) => item.id).length ||
          !saleType.id ||
          !contact.id
        }
        onCancel={() => setShow(false)}
      />
    </AddProjectStyled>
  );
};
